import React, { Component } from 'react';

class Work extends Component {
    state = {
        data: {
            "heading": "How DevResponse works?",
            "headingText": "You select from cerefully currated (guaranteed to be delivered) services, approve suggested specifications, request proposals, review fixed bids, agree to contract terms and review deliverables. Entire process in a lot less time than you think.",
          },
        workData: [
            {
              "id": 1,
              "image": "/img/work_thumb_1.png",
              "title": "Get project specifications",
              "content": "Explore our collection of tried and true fixed price service specifications"
            },
            {
              "id": 2,
              "image": "/img/work_thumb_2.png",
              "title": "Create Account",
              "content": "Establish your very own trusted escrow account. Source services with confidence."
            },
            {
              "id": 3,
              "image": "/img/work_thumb_3.png",
              "title": "Enjoy the deliverables",
              "content": "We verify and certifiy the results to make sure develirables match the specifications"
            }
          ]
    }

    render() {
        return (
            <section id="work" className="section work-area bg-overlay overflow-hidden ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-10">
                            {/* Work Content */}
                            <div className="work-content text-center">
                                <h2 className="text-white">{this.state.data.heading}</h2>
                                <p className="text-white my-3 mt-sm-4 mb-sm-5">{this.state.data.headingText}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {this.state.workData.map((item, idx) => {
                            return(
                                <div key={`w_${idx}`} className="col-12 col-md-4">
                                {/* Single Work */}
                                <div className="single-work text-center p-3">
                                    {/* Work Icon */}
                                    <div className="work-icon">
                                        <img className="avatar-md" src={item.image} alt="" />
                                    </div>
                                    <h3 className="text-white py-3">{item.title}</h3>
                                    <p className="text-white">{item.content}</p>
                                </div>
                            </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        );
    }
}

export default Work;