import React, { Component } from 'react';

class PricingSection extends Component {
    state = {
        data: {
            "iconClass": "far fa-lightbulb text-primary mr-1",
            "preHeading": "Flexible",
            "preHeadingspan": "Pricing",
            "heading": "Unlock Full Power Of DevResponse",
            "headingText": "Manage your own library of project specifications. Instantly publish your projects into platform of your choice. Access advanced enterprise tools and much more.",
            "headingTexttwo": "Generate custom proposals and more.",
            "text": "Not sure what to choose?",
            "textLink": "Contact Us",
            "pricingData": [
              {
                "id": 1,
                "planImage": "/img/price_basic.png",
                "planTitle": "Basic Account",
                "planPrice": "$49",
                "planTerm": "per year",
                "planBtn": "Sign Up",
                "li_1": "1 GB Workspace",
                "li_2": "Standard Branding",
                "li_3": "24/7 Tech Support",
                "li_4": "Daily Backups"
              },
              {
                "id": 2,
                "planImage": "/img/price_premium.png",
                "planTitle": "Pro Account",
                "planPrice": "$149",
                "planTerm": "per year",
                "planBtn": "Sign Up",
                "li_1": "10 GB Workspace",
                "li_2": "Custom Branding",
                "li_3": "Unlimited Support",
                "li_4": "Daily Backups"
              }
            ]
          },
    }
    render() {
        return (
            <section id="pricing" className="section price-plan-area bg-gray overflow-hidden ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-10">
                            {/* Section Heading */}
                            <div className="section-heading text-center">
                                <h2>{this.state.data.heading}</h2>
                                <p className="d-none d-sm-block mt-4">{this.state.data.headingText}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-lg-8">
                            <div className="row price-plan-wrapper">
                                {this.state.data.pricingData.map((item, idx) => {
                                    return(
                                        <div key={`p_${idx}`} className="col-12 col-md-6 mt-4">
                                            {/* Single Price Plan */}
                                            <div className="single-price-plan text-center p-5">
                                                {/* Plan Thumb */}
                                                <div className="plan-thumb">
                                                    <img className="avatar-lg" src={item.planImage} alt="" />
                                                </div>
                                                {/* Plan Title */}
                                                <div className="plan-title my-2 my-sm-3">
                                                    <h3 className="text-uppercase">{item.planTitle}</h3>
                                                </div>
                                                {/* Plan Price */}
                                                <div className="plan-price pb-2 pb-sm-3">
                                                    <h1 className="color-primary">{item.planPrice}<br/><small className="fw-7"> {item.planTerm}</small></h1>
                                                </div>
                                                {/* Plan Description */}
                                                <div className="plan-description">
                                                    <ul className="plan-features">
                                                        <li className="border-top py-3">{item.li_1}</li>
                                                        <li className="border-top py-3">{item.li_2}</li>
                                                        <li className="border-top py-3">{item.li_3}</li>
                                                        <li className="border-top border-bottom py-3">{item.li_4}</li>
                                                    </ul>
                                                </div>
                                                {/* Plan Button */}
                                                <div className="plan-button">
                                                    <a href="/#" className="btn mt-4">{item.planBtn}</a>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center pt-5">
                        <p className="text-body pt-4 fw-5">{this.state.data.text} <a href="/#">{this.state.data.textLink}</a></p>
                    </div>
                </div>
            </section>
        );
    }
}

export default PricingSection;