import React, { Component } from 'react';

const initData = {
    preHeading: "Top",
    preHeadingspan: "Ideas",
    heading: "Discover Most Popular Solution Categories",
    headingText: "Find high-quality, proven and tested digital solutions at every price point. No hourly rates, just action-based pricing. Find the right talent by communicating exactly what you need. DevResponse connects clients to expert freelancers who are available to work on your project.",
}

const data = [
    {
        image: "/img/featured_image_1.png",
        title: "Graphics & Design",
        content: "Logo & Brand Identity. Web & App Design. Art & Illustration. Marketing Design"
    },
    {
        image: "/img/featured_image_2.png",
        title: "Programming & Tech",
        content: "Website Development. Application Development. Support & Cybersecurity. AI & Data Mining"
    },
    {
        image: "/img/featured_image_3.png",
        title: "Writing & Translation",
        content: "Content Writing & Editing. Business Copy. Storytelling. Career Writing"
    },
    {
        image: "/img/featured_image_4.png",
        title: "Digital Marketing",
        content: "Search Engine Optimization. Social Media Marketing. Social Media Advertising."
    },
    {
        image: "/img/featured_image_5.png",
        title: "Video & Animation",
        content: "Social & Marketing Videos. Video Editing & Post-Production. Product & Explainer Videos."
    },
    {
        image: "/img/featured_image_6.png",
        title: "Music & Audio",
        content: "Music Production & Writing. Audio Engineering & Post Production. Voice Over & Streaming."
    }
]

class FeatureSection extends Component {
    state = {
        initData: {},
        data: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            data: data
        })
    }
    render() {
        return (
            <section id="features" className="section features-area style-two overflow-hidden ptb_100">
                <div className="container">
                    <div className="row">
                    <div className="col-12 col-md-10 col-lg-10">
                        {/* Section Heading */}
                        <div className="section-heading">
                        <h2>{this.state.initData.heading}</h2>
                        <p className="d-none d-sm-block mt-4">{this.state.initData.headingText}</p>
                        </div>
                    </div>
                    </div>
                    <div className="row">
                        {this.state.data.map((item, idx) => {
                            return(
                                <div key={`ffd_${idx}`} className="col-12 col-md-6 col-lg-4 my-3 res-margin">
                                    {/* Image Box */}
                                    <div className="image-box text-center icon-1 p-5">
                                    {/* Featured Image */}
                                    <div className="featured-img mb-3">
                                        <img className="avatar-sm" src={item.image} alt="" />
                                    </div>
                                    {/* Icon Text */}
                                    <div className="icon-text">
                                        <h3 className="mb-2">{item.title}</h3>
                                        <p>{item.content}</p>
                                    </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        );
    }
}

export default FeatureSection;