import React, { Component } from "react";

class Header extends Component {
  render() {
    return (
      <header className="navbar navbar-sticky navbar-expand-lg navbar-dark">
        <div className="container position-relative">
          <a className="navbar-brand" href="/#">
            <div className="navbar-brand-regular">
              <h2 className="text-white font-weight-bold">DevResponse</h2>
            </div>
            <div className="navbar-brand-sticky">
              <h2 className="text-primary font-weight-bold">DevResponse</h2>
            </div>
            {/* <img className="navbar-brand-regular" src={this.props.imageData} alt="brand-logo" /> */}
            {/* <img className="navbar-brand-sticky" src="/img/logo.png" alt="sticky brand-logo" /> */}
          </a>
          <button
            className="navbar-toggler d-lg-none"
            type="button"
            data-toggle="navbarToggler"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="navbar-inner">
            {/*  Mobile Menu Toggler */}
            <button
              className="navbar-toggler d-lg-none"
              type="button"
              data-toggle="navbarToggler"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <nav>
              <ul className="navbar-nav" id="navbar-nav">
                <li className="nav-item">
                  <a className="nav-link scroll" href="#features">
                    Categories
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link scroll" href="#work">
                    How it works
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link scroll" href="#faq">
                    FAQ
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link scroll" href="#team">
                    Our Experts
                  </a>
                </li>
                {/* <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Pages
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        <li className="dropdown-submenu"><a className="dropdown-item dropdown-toggle" href="/#" data-toggle="dropdown">Inner Pages<span className="badge badge-pill badge-warning ml-2">New</span></a>
                                            <ul className="dropdown-menu">
                                                <li>
                                                    <a className="dropdown-item" href="/pricing">Pricing</a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="/download-page">Download</a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="/subscribe-page">Newsletter</a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="/thank-you">Thank you</a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="/coming-soon">Coming Soon</a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="/error-page">404</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="dropdown-submenu"><a className="dropdown-item dropdown-toggle" href="/#" data-toggle="dropdown">Blog Pages</a>
                                            <ul className="dropdown-menu">
                                                <li>
                                                    <a className="dropdown-item" href="/blog-two-column">Blog- 2 Column</a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="/blog-three-column">Blog- 3 Column</a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="/blog-left-sidebar">Blog- Left Sidebar</a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="/blog-right-sidebar">Blog- Right Sidebar</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="dropdown-submenu"><a className="dropdown-item dropdown-toggle" href="/#" data-toggle="dropdown">Blog Details</a>
                                            <ul className="dropdown-menu">
                                                <li>
                                                    <a className="dropdown-item" href="/blog-details-left-sidebar">Blog Details- Left Sidebar</a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="/blog-details-right-sidebar">Blog Details- Right Sidebar</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="dropdown-submenu"><a className="dropdown-item dropdown-toggle" href="/#" data-toggle="dropdown">Accounts<span className="badge badge-pill badge-warning ml-2">New</span></a>
                                            <ul className="dropdown-menu">
                                                <li>
                                                    <a className="dropdown-item" href="/login">Login</a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="/signup">Signup</a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="/forgot">Reset Password</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="/reviews">Reviews</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="/faq">FAQ</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="/contact-page">Contact</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item disabled" href="/#">More Coming Soon</a>
                                        </li>
                                    </ul>
                                </li> */}
              </ul>
            </nav>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
