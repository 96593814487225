import React, { Component } from 'react';

class FaqSection extends Component {
    state = {
        data: {
            "heading": "Frequently asked questions",
            "headingTwo": "Have questions? Look here",
            "headingText": "DevResponse is a Digital Marketplace - we transform your ideas into actionable tasks. Use our library of well-defined project specifications to communicate your requirements to the world. Connect with qualified contractors that can execute projects on time and budgets.",
            "headingTexttwo": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.",
            "faqText": "Haven't find suitable answer?",
            "faqTextLink": "Tell us what you need.",
            "faqData": [
              {
                "id": 1,
                "title": "How DevResponse works?",
                "content": "We maintain an open source library of project specifications that help you clearly communicate your project requirements to a qualified contractor. We organize information for you so you can find the well qualified consultant and use our project templates to start working on your ideas right away. We take care of the 'paperwork'to make it easy to transact online."
              },
              {
                "id": 2,
                "title": "Do you offer you project templates for free?",
                "content": "Yes. We maintain an open source library of free project templates and description of digital services and keep track records of the real world implementations that rely on our information. Product development can be an administrative beast. We can help you with that. DevResponse brings all your notes, project specs, action items into one organized place. With DevResponse you can create the tools your team needs."
              },
              {
                "id": 3,
                "title": "Do you verify new users?",
                "content": "As part of our efforts to keep DevResponse a safe place for our community and to conduct business, we often require sellers to verify their identity. There are certain cases where some sellers may receive an “Identity Verified” badge after their identification and information have been successfully verified—this badge further reflects trustworthiness and authenticity to their customers."
              }
            ],
            "faqDataTwo": [
              {
                "id": 1,
                "title": "Who can create DevResponse account?",
                "content": "DevResponse’s Terms of Service require that all users be at least 18 years of age and of legal age to form a binding contract. Users under 18 and at least 13 years of age are permitted to use the site only through an account owned by their parent or legal guardian with their appropriate permission. Users under the age of 13 are not permitted to use the site."
              },
              {
                "id": 3,
                "title": "How can I get paid on DevResponse?",
                "content": "Signing up with DevResponse is free but only verified registered users can buy and sell on DevResponse. We do not charge any commission for our service. When you sign up, your account will automatically be a free guest account. Almost anyone can find something within their skillset to offer on DevResponse. Explore our various categories, see where your skills fit in, and decide how you want to offer your services."
              },              
              {
                "id": 2,
                "title": "How do you deal with violations of terms and conditions?",
                "content": "A DevResponse’s account can be disabled for various reasons. We aim to keep our marketplace a safe and enjoyable place for all of our customers. Read our terms and conditions, review the most common causes for an account to be reviewed and disabled. Find additional details in our Community Standards and DevResponse’s Terms of Service."
              }
            ],
            "faqDataThree": [
              {
                "id": 1,
                "title": "How to install sApp?",
                "content": "The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text."
              },
              {
                "id": 2,
                "title": "Can I get support from the Author?",
                "content": "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source."
              },
              {
                "id": 3,
                "title": "Do you have a free trail?",
                "content": "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
              },
              {
                "id": 4,
                "title": "How can I edit my personal information?",
                "content": "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt."
              },
              {
                "id": 5,
                "title": "Contact form isn't working?",
                "content": "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text."
              }
            ]
          },
        faqData: [],
        faqDataTwo: []
    }
   
    render() {
        return (
            <section id="faq" className="section faq-area style-two ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-7">
                        {/* Section Heading */}
                        <div className="section-heading text-center">
                            <h2 className="text-capitalize">{this.state.data.heading}</h2>
                            <p className="d-none d-sm-block mt-4">{this.state.data.headingText}</p>
                            <p className="d-block d-sm-none mt-4">{this.state.data.headingTexttwo}</p>
                        </div>
                    </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12">
                            {/* FAQ Content */}
                            <div className="faq-content">
                                {/* sApp Accordion */}
                                <div className="accordion" id="sApp-accordion">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-6">
                                        {/* Single Card */}
                                        {this.state.data.faqData.map((item, idx) => {
                                            return(
                                                <div key={`fo_${idx}`} className="card border-0">
                                                    {/* Card Header */}
                                                    <div className="card-header bg-inherit border-0 p-0">
                                                    <h2 className="mb-0">
                                                        <button className="btn px-0 py-2" type="button">
                                                            {item.title}
                                                        </button>
                                                    </h2>
                                                    </div>
                                                    {/* Card Body */}
                                                    <div className="card-body px-0 py-3">
                                                        {item.content}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        </div>
                                        <div className="col-12 col-md-6">
                                        {/* Single Card */}
                                        {this.state.data.faqDataTwo.map((item, idx) => {
                                            return(
                                                <div key={`ft_${idx}`} className="card border-0">
                                                    {/* Card Header */}
                                                    <div className="card-header bg-inherit border-0 p-0">
                                                    <h2 className="mb-0">
                                                        <button className="btn px-0 py-2" type="button">
                                                            {item.title}
                                                        </button>
                                                    </h2>
                                                    </div>
                                                    {/* Card Body */}
                                                    <div className="card-body px-0 py-3">
                                                        {item.content}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        </div>
                                    </div>
                                    {/* <div className="row justify-content-center">
                                        <p className="text-body text-center pt-4 fw-5">{this.state.data.faqText} <a href="/#">{this.state.data.faqTextLink}</a></p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default FaqSection;