import React, { Component } from 'react';

class FooterSection extends Component {
    state = {
        data: {
            "image": "/img/logo.png",
            "text": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quis non, fugit totam vel laboriosam vitae.",
            "linkText_1": "Useful Links",
            "linkText_2": "Product Help",
            "linkText_3": "Download",
            "iconList": [
              {
                "id": 1,
                "link": "facebook",
                "iconClass": "fab fa-facebook-f"
              },
              {
                "id": 2,
                "link": "twitter",
                "iconClass": "fab fa-twitter"
              },
              {
                "id": 3,
                "link": "google-plus",
                "iconClass": "fab fa-google-plus-g"
              },
              {
                "id": 4,
                "link": "vine",
                "iconClass": "fab fa-vine"
              }
            ],
            "footerList_1": [
              {
                "id": 1,
                "text": "Home"
              },
              {
                "id": 2,
                "text": "About Us"
              },
              {
                "id": 3,
                "text": "Services"
              },
              {
                "id": 4,
                "text": "Blog"
              },
              {
                "id": 5,
                "text": "Contact"
              }
            ],
            "footerList_2": [
              {
                "id": 1,
                "text": "FAQ"
              },
              {
                "id": 2,
                "text": "Privacy Policy"
              },
              {
                "id": 3,
                "text": "Support"
              },
              {
                "id": 4,
                "text": "Terms & Conditions"
              },
              {
                "id": 5,
                "text": "Contact"
              }
            ],
            "footerList_3": [
              {
                "id": 1,
                "image": "/img/google-play-black.png"
              },
              {
                "id": 2,
                "image": "/img/app-store-black.png"
              }
            ],
            "footerList_4": [
              {
                "id": 1,
                "text": "Affiliate program"
              },
              {
                "id": 2,
                "text": "Terms & Conditions"
              },
              {
                "id": 3,
                "text": "Privacy Policy"
              },
              {
                "id": 4,
                "text": "Refund Policy"
              }
            ]
          },
    }
   
    render() {
        return (
            <div>
                <div className="height-emulator d-none d-lg-block" />
                <footer className="footer-area footer-fixed">
                    <div className="footer-bottom">
                        <div className="container">
                        <div className="row">
                            <div className="col-12">
                            {/* Copyright Area */}
                            <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                                {/* Copyright Left */}
                                <div className="copyright-left">© Copyrights 2022 DevResponse All rights reserved.</div>
                                {/* Copyright Right */}
                                <div className="copyright-right">Made with <i className="fas fa-heart" /> By <a href="/#">DevResponse</a></div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

export default FooterSection;